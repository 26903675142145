'use client';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  PromocodeLanding,
  RecoveryForm,
  SignInForm,
  SignUpForm,
  SignUpFormMobile,
} from '@/features/auth-modal-content/ui';
import { ModalWrapper } from '@/shared/ui/modal-wrapper';
import { formsMap } from '@/store/authorization/authorization';
import { useStore } from '@/store/context';

export const AuthorizationModal = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const auth = useStore().authorization;

    return (
      <ModalWrapper
        close={() => auth.closeModal()}
        isOpened={!!auth.openedForm}
        isMobile={isMobile}
      >
        <>
          {auth.openedForm === formsMap.PROMO_LANDING && <PromocodeLanding />}
          {auth.openedForm === formsMap.SIGN_UP &&
            (isMobile ? <SignUpFormMobile /> : <SignUpForm />)}
          {auth.openedForm === formsMap.SIGN_IN && <SignInForm isMobile />}
          {auth.openedForm === formsMap.RECOVERY && <RecoveryForm isMobile />}
        </>
      </ModalWrapper>
    );
  },
);
