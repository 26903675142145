import * as Yup from 'yup';

export const validationSchema = Yup.object({
  userName: Yup.string()
    .test('min', 'Nickname must be 3 characters or more', value => {
      if (!value) return true;
      return value.length >= 3;
    })
    .test('min', 'Nickname must be 24 characters or less', value => {
      if (!value) return true;
      return value.length <= 24;
    })
    .test(
      'max-2-special-symbols',
      'Only Latin letters and at most 2 special symbols (_ and .)',
      value => {
        if (!value) return true;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(value)) {
          return true;
        }
        const specialSymbolsRegex = /[_\.]/g;
        const invalidSymbolsRegex = /[^a-zA-Z0-9_\.\s]/;
        if (invalidSymbolsRegex.test(value)) return false;
        const specialSymbolsCount = (value.match(specialSymbolsRegex) || [])
          .length;

        return specialSymbolsCount <= 2;
      },
    )
    .required('Required field'),
  password: Yup.string()
    .test('min', 'Must be 6 characters or more', value => {
      if (!value) return true;
      return value.length >= 6;
    })
    .test('min', 'Must be 32 characters or less', value => {
      if (!value) return true;
      return value.length <= 32;
    })
    .required('Required field'),
});
