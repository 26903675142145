'use client';
import { useAuth } from '@hooks/client/use-auth';
import cn from 'clsx';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import React, { useEffect, useState } from 'react';
import Turnstile from 'react-turnstile';

import { validationSchemaDesktop } from '@/features/auth-modal-content/ui/sign-up-form/validation';
import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import { SOCIAL_AUTH } from '@/shared/api/endpoints';
import { Illustration, IllustrationModal, ModalContent } from '@/shared/ui';
import { Divider, Typography } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { useStore } from '@/store/context';

const BASE_URL = 'https://users.shipblox.com';

export const SignUpForm = observer(() => {
  const auth = useStore().authorization;
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const router = useRouter();
  const { signUp } = useAuth();

  const formik = useFormik({
    initialValues: {
      userName: '',
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema: validationSchemaDesktop,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const requestSocialAuth = (provider: 'google' | 'discord') => {
    window.location.replace(`${BASE_URL}${SOCIAL_AUTH(provider)}`);
  };

  const toggleAgreementAccepted = () => {
    setAgreementAccepted(!agreementAccepted);
  };

  const openAgreementPage = () => {
    router.push('/policy');
    auth.closeModal();
  };

  const onSubmit = async (captcha: string) => {
    signUp({
      email: formik.values.email,
      password: formik.values.password,
      username: formik.values.userName,
      captcha,
    });
  };

  const socialButtonClasses = cn(
    styles['bottom-container-button'],
    styles['social_btn'],
  );
  const bottomLinkClasses = cn(styles['text-regular'], styles['link']);

  useEffect(() => {
    if (auth.step === 1) formik.validateField('password');
  }, [auth.step]);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'sign-up'} />
      <ModalContent.Title
        title={'Let\'s start!'}
        subtitle={'Enter registration details'}
      />

      <ModalContent.Body>
        {auth.step === 0 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'inbox'}
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                onInput={() => formik.setFieldTouched('email')}
                placeholder="Email"
                errorMessage={
                  auth.authError ||
                  (formik.touched.email ? formik.errors.email : '')
                }
                withErrorMessage={false}
              />
              <InputAuth
                value={formik.values.userName}
                iconName={'user'}
                onChange={formik.handleChange('userName')}
                onBlur={formik.handleBlur('userName')}
                onInput={() => formik.setFieldTouched('userName')}
                placeholder="Username"
                errorMessage={
                  auth.authError ||
                  (formik.touched.userName ? formik.errors.userName : '')
                }
                withErrorMessage={false}
              />
              <InputAuth
                iconName={'lock-password'}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                onInput={() => formik.setFieldTouched('password')}
                placeholder="Password"
                withErrorMessage={false}
                securityInput
                errorMessage={
                  formik.touched.password ? formik.errors.password : ''
                }
              />
            </div>
            <div className={styles['bottom-container']}>
              <div className={styles['terms-container']}>
                <Checkbox
                  checked={agreementAccepted}
                  onChange={toggleAgreementAccepted}
                />
                <Typography className={styles['text-regular']}>
                  I accept the terms of the
                </Typography>
                <Typography
                  onClick={openAgreementPage}
                  className={bottomLinkClasses}
                >
                  User Agreement
                </Typography>
              </div>
              <Button
                onClick={() => auth.setStep(1)}
                variant="primary"
                iconLeft={
                  <Illustration
                    style={{ color: '#FFF' }}
                    spanTagClassName={styles['button-icon']}
                    name={'user-check-rounded'}
                    spriteName={'icons'}
                  />
                }
                text={'Sign up'}
                width="full-w"
                disabled={!formik.isValid || !agreementAccepted}
                className={styles['bottom-container-button']}
              />
              <div className={styles['divider_container']}>
                <Divider direction={'horizontal'} className={styles.divider} />
                <Typography weight="medium">Or Sign Up with</Typography>
                <Divider direction={'horizontal'} className={styles.divider} />
              </div>
              <div className={styles['social-container']}>
                <Button
                  onClick={() => requestSocialAuth('google')}
                  variant="secondary"
                  className={socialButtonClasses}
                  iconLeft={
                    <Illustration
                      name={'social-google'}
                      spriteName={'icons'}
                      size={22}
                    />
                  }
                  text="Google"
                  width="full-w"
                />
                <Button
                  onClick={() => requestSocialAuth('discord')}
                  iconLeft={
                    <Illustration
                      style={{ color: '#7085F4' }}
                      name={'discord'}
                      spriteName={'icons'}
                      size={22}
                    />
                  }
                  variant="secondary"
                  className={socialButtonClasses}
                  text="Discord"
                  width="full-w"
                />
              </div>
            </div>
          </>
        )}
        {auth.step === 1 && (
          <div className={styles['center-container']}>
            <Turnstile
              theme={'light'}
              sitekey={process.env.NEXT_PUBLIC_SITE_KEY!}
              language="en"
              onVerify={onSubmit}
            />
          </div>
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        {auth.step === 0 && (
          <div className={styles['bottom-slot']}>
            <Typography className={styles['text-regular']}>
              Already have an account?
            </Typography>
            <Typography
              onClick={() => auth.openSignIn()}
              className={bottomLinkClasses}
            >
              Sign In
            </Typography>
          </div>
        )}
      </ModalContent.Footer>
    </ModalContent>
  );
});
