import { deleteCookie, setCookie } from 'cookies-next';
import { makeAutoObservable } from 'mobx';

import {
  RESET_PASSWORD_REQUEST,
  SIGN_IN,
  SIGN_UP,
  USER_INFO,
  VERIFY_SOCIAL_AUTH,
} from '@/shared/api/endpoints';
import usersApi from '@/shared/api/usersApi';
import {
  AuthInfo,
  AuthResponse,
  ProfileResponse,
  UserInfo,
  UserInfoResponse,
} from '@/store/authorization/types';

export const formsMap = {
  SIGN_IN: 'signIn',
  SIGN_UP: 'user',
  RECOVERY: 'recovery',
  RESET: 'reset',
  PROMO_LANDING: 'promoLanding',
} as const;

type Keys = keyof typeof formsMap;
type AvailableForms = (typeof formsMap)[Keys] | null;

class AuthorizationStore {
  userInfo: UserInfo | null = null;
  authError: string = '';
  openedForm: AvailableForms = null;
  authRedirectPath: string | null = null;
  step: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async getUserInfo() {
    try {
      const response = await usersApi.get<ProfileResponse>(USER_INFO);
      this.userInfo = response.data.data;
    } catch (err) {
      this.logOut();
    }
  }

  async resetPasswordRequest(login: string) {
    try {
      await usersApi.post(RESET_PASSWORD_REQUEST, { login });
      this.setStep(1);
    } catch (err) {
      this.setAuthErrorMessage('Invalid input');
    }
  }

  async verifySocialAuth(provider: 'google' | 'discord', query: string) {
    try {
      const response = await usersApi.get<AuthResponse>(
        VERIFY_SOCIAL_AUTH(provider, query),
      );
      this.setAuthInfo(response.data.data);
      this.closeModal();
    } catch (err) {
      console.log(err);
    }
  }

  setAuthErrorMessage(message: string) {
    this.authError = message;
    setTimeout(() => {
      this.authError = '';
    }, 4000);
  }

  setAuthInfo(info: AuthInfo) {
    this.userInfo = info.user;
    setCookie('jwt', info.jwt, { maxAge: 259200 });
  }

  setAuthRedirectPath(path: string | null) {
    this.authRedirectPath = path;
  }

  logOut() {
    this.userInfo = null;
    deleteCookie('jwt');
  }

  openPromoLanding() {
    this.step = 0;
    this.openedForm = formsMap.PROMO_LANDING;
  }

  openSignIn() {
    this.step = 0;
    this.openedForm = formsMap.SIGN_IN;
  }
  openSignUp() {
    this.step = 0;
    this.openedForm = formsMap.SIGN_UP;
  }

  openReset() {
    this.step = 0;
    this.openedForm = formsMap.RESET;
  }
  openRecovery() {
    this.step = 0;
    this.openedForm = formsMap.RECOVERY;
  }

  closeModal() {
    this.step = 0;
    this.openedForm = null;
    this.authRedirectPath = null;
  }

  setStep(value: number) {
    this.step = value;
  }
}

export const authorizationStore = new AuthorizationStore();
