'use client';
import { useAuth } from '@hooks/client/use-auth';
import cn from 'clsx';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import React, { useEffect } from 'react';
import Turnstile from 'react-turnstile';

import {
  baseValidationSchema,
  validationSchemaMobile,
} from '@/features/auth-modal-content/ui/sign-up-form/validation';
import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import { Illustration, IllustrationModal, ModalContent } from '@/shared/ui';
import { Divider, Typography } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { useStore } from '@/store/context';

export const SignUpFormMobile = observer(() => {
  const auth = useStore().authorization;
  const router = useRouter();
  const { signUp } = useAuth();

  const formik = useFormik({
    initialValues: {
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema:
      auth.step === 0 ? baseValidationSchema : validationSchemaMobile,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const openAgreementPage = () => {
    router.push('/policy');
    auth.closeModal();
  };

  const onSubmit = async (captcha: string) => {
    signUp({
      email: formik.values.email,
      password: formik.values.password,
      username: formik.values.userName,
      captcha,
    });
  };

  const socialButtonClasses = cn(
    styles['bottom-container-button'],
    styles['social_btn'],
  );
  const bottomLinkClasses = cn(styles['text-regular'], styles['link']);

  useEffect(() => {
    if (auth.step === 0) formik.validateForm();
    if (auth.step === 1) formik.validateField('password');
  }, [auth.step]);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'sign-up'} />
      <ModalContent.Title
        title={'Let\'s start!'}
        subtitle={'Enter registration details'}
      />

      <ModalContent.Body>
        {auth.step === 0 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'inbox'}
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                onInput={() => formik.setFieldTouched('email')}
                placeholder="Email"
                errorMessage={
                  auth.authError ||
                  (formik.touched.email ? formik.errors.email : '')
                }
                withErrorMessage={false}
              />
              <InputAuth
                value={formik.values.userName}
                iconName={'user'}
                onChange={formik.handleChange('userName')}
                onBlur={formik.handleBlur('userName')}
                onInput={() => formik.setFieldTouched('userName')}
                placeholder="Username"
                errorMessage={
                  auth.authError ||
                  (formik.touched.userName ? formik.errors.userName : '')
                }
                withErrorMessage={false}
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={() => auth.setStep(1)}
                variant="primary"
                text={'Continue'}
                width="full-w"
                disabled={!formik.isValid}
                className={styles['bottom-container-button']}
              />
              <Button
                onClick={() => auth.openSignIn()}
                variant="secondary"
                text="Sign In"
                width="full-w"
                className={styles['bottom-container-button']}
              />
              <div className={styles['divider_container']}>
                <Divider direction={'horizontal'} className={styles.divider} />
                <Typography weight="medium">Or Sign Up with</Typography>
                <Divider direction={'horizontal'} className={styles.divider} />
              </div>
              <div className={styles['social-container']}>
                <Button
                  onClick={() => signIn('google')}
                  variant="secondary"
                  className={socialButtonClasses}
                  iconLeft={
                    <Illustration
                      name={'social-google'}
                      spriteName={'icons'}
                      size={22}
                    />
                  }
                  text="Google"
                  width="full-w"
                />
                <Button
                  onClick={() => signIn('discord')}
                  iconLeft={
                    <Illustration
                      style={{ color: '#7085F4' }}
                      name={'discord'}
                      spriteName={'icons'}
                      size={22}
                    />
                  }
                  variant="secondary"
                  className={socialButtonClasses}
                  text="Discord"
                  width="full-w"
                />
              </div>
            </ModalContent.Footer>
          </>
        )}
        {auth.step === 1 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'lock-password'}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                onInput={() => formik.setFieldTouched('password')}
                placeholder="Password"
                securityInput
                errorMessage={
                  formik.touched.password ? formik.errors.password : ''
                }
              />
              <InputAuth
                iconName={'lock-password'}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange('confirmPassword')}
                onBlur={formik.handleBlur('confirmPassword')}
                onInput={() => formik.setFieldTouched('confirmPassword')}
                placeholder="Confirm password"
                securityInput
                errorMessage={
                  formik.touched.confirmPassword
                    ? formik.errors.confirmPassword
                    : ''
                }
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={() => auth.setStep(2)}
                variant="primary"
                text={'Create an account'}
                width="full-w"
                disabled={!formik.isValid}
                className={styles['bottom-container-button']}
              />
              <Button
                onClick={() => auth.setStep(0)}
                iconLeft={
                  <Illustration
                    style={{ color: '#6941C699' }}
                    spanTagClassName={styles['button-icon']}
                    name={'square-arrow-left'}
                    spriteName={'icons'}
                  />
                }
                variant="secondary"
                text={'Back'}
                width="full-w"
                className={styles['bottom-container-button']}
              />
            </ModalContent.Footer>
          </>
        )}
        {auth.step === 2 && (
          <div className={styles['center-container']}>
            <Turnstile
              theme={'light'}
              sitekey={process.env.NEXT_PUBLIC_SITE_KEY!}
              language="en"
              onVerify={onSubmit}
            />
          </div>
        )}
      </ModalContent.Body>
      {auth.step === 0 && (
        <ModalContent.Footer>
          <div className={styles['bottom-slot']}>
            <Typography className={styles['text-regular']}>
              By continuing you accept the terms of the
            </Typography>
            <Typography
              onClick={openAgreementPage}
              className={bottomLinkClasses}
            >
              User Agreement
            </Typography>
          </div>
        </ModalContent.Footer>
      )}
    </ModalContent>
  );
});
