'use client';
import { useTimer } from '@hooks/client';
import cn from 'clsx';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import {
  Button,
  Description,
  Illustration,
  IllustrationModal,
  ModalContent,
  Typography,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { useStore } from '@/store/context';

import { RecoveryFormProps } from './types';
import { fullValidationSchema, initialValidationSchema } from './validation';

const subTitles = [
  'Please enter your e-mail or username',
  'Confirm your e-mail address',
  'Please set your password',
];

export const RecoveryForm = observer(({ isMobile }: RecoveryFormProps) => {
  const auth = useStore().authorization;

  const { timer, count, resetCountdown, startCountdown } = useTimer({
    duration: 60,
  });

  const bottomLinkClasses = cn(styles['text-regular'], styles['link']);

  const getValidationSchema = (step: number) =>
    step ? fullValidationSchema : initialValidationSchema;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema: getValidationSchema(auth.step),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const isEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(value);
  };

  const sendResetRequest = () => {
    auth.resetPasswordRequest(formik.values.email);
  };

  const onSubmit = async () => {
    auth.closeModal();
  };

  useEffect(() => {
    if (auth.step === 1) {
      resetCountdown();
      startCountdown();
    }
    if (auth.step === 2) formik.validateField('password');
  }, [auth.step]);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'password-recovery'} />
      <ModalContent.Title
        title={'Password recovery'}
        subtitle={subTitles[auth.step]}
      />

      <ModalContent.Body>
        {auth.step === 0 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'inbox'}
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onInput={() => formik.setFieldTouched('email')}
                onBlur={formik.handleBlur('email')}
                placeholder="E-mail or username"
                errorMessage={
                  auth.authError ||
                  (formik.touched.email ? formik.errors.email : '')
                }
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={sendResetRequest}
                iconRight={
                  !isMobile && (
                    <Illustration
                      style={{ color: '#FFF' }}
                      spanTagClassName={styles['button-icon']}
                      name={'square-arrow-right'}
                      spriteName={'icons'}
                    />
                  )
                }
                variant="primary"
                text="Continue"
                width="full-w"
                disabled={!formik.isValid}
                className={styles['bottom-container-button']}
              />
              {isMobile && (
                <Button
                  onClick={() =>
                    auth.userInfo ? auth.openReset() : auth.openSignIn()
                  }
                  variant="secondary"
                  iconLeft={
                    <Illustration
                      style={{ color: '#6941C6' }}
                      spanTagClassName={styles['button-icon']}
                      name={'square-arrow-left'}
                      spriteName={'icons'}
                    />
                  }
                  text={auth.userInfo ? 'Back' : 'Back to sign in'}
                  width="full-w"
                  className={styles['bottom-container-button']}
                />
              )}
            </ModalContent.Footer>
          </>
        )}
        {auth.step === 1 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'inbox'}
                placeholder={
                  isEmail(formik.values.email) ? 'E-mail' : 'Username'
                }
                value={formik.values.email}
                disabled
                withErrorMessage={false}
              />
              <Description
                message={
                  'Please check your email inbox and click on the provided link to confirm your e-mail'
                }
              />
              <Description
                message={
                  'You can change the email address if you made a mistake!'
                }
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={() => {
                  sendResetRequest();
                  resetCountdown();
                  startCountdown();
                }}
                variant="primary"
                disabled={!!count}
                text={`Send again ${count ? `after ${timer.substring(3)}` : ''}`}
                width="full-w"
                className={styles['bottom-container-button']}
              />
              <Button
                onClick={() => auth.setStep(0)}
                variant="secondary"
                iconLeft={
                  <Illustration
                    style={{ color: '#6941C6' }}
                    spanTagClassName={styles['button-icon']}
                    name={'square-pen'}
                    spriteName={'icons'}
                  />
                }
                text="Change E-mail or username"
                width="full-w"
                className={styles['bottom-container-button']}
              />
            </ModalContent.Footer>
          </>
        )}
        {auth.step === 2 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                value={formik.values.email}
                iconName={'user'}
                placeholder="Email"
                checked
                withErrorMessage={false}
              />
              <InputAuth
                iconName={'lock-password'}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                onInput={() => formik.setFieldTouched('password')}
                placeholder="Password"
                withErrorMessage={false}
                securityInput
                errorMessage={
                  formik.touched.password ? formik.errors.password : ''
                }
              />
              <InputAuth
                iconName={'lock-password'}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange('confirmPassword')}
                onBlur={formik.handleBlur('confirmPassword')}
                onInput={() => formik.setFieldTouched('confirmPassword')}
                placeholder="Confirm password"
                withErrorMessage={false}
                securityInput
                errorMessage={
                  formik.touched.confirmPassword
                    ? formik.errors.confirmPassword
                    : ''
                }
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={onSubmit}
                variant="primary"
                text="Confirm"
                width="full-w"
                className={styles.button}
                disabled={!formik.isValid}
                iconLeft={
                  <Illustration
                    style={{ color: '#fff' }}
                    name={'user-check-rounded'}
                    spriteName={'icons'}
                    size={22}
                  />
                }
              />
            </ModalContent.Footer>
          </>
        )}
      </ModalContent.Body>
      {!isMobile && auth.step === 0 && (
        <ModalContent.Footer>
          <div className={styles['bottom-slot']}>
            <Typography className={styles['text-regular']}>
              Remember your password?
            </Typography>
            <Typography
              onClick={() => auth.openSignIn()}
              className={bottomLinkClasses}
            >
              Sign In
            </Typography>
          </div>
        </ModalContent.Footer>
      )}
    </ModalContent>
  );
});
