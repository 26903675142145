'use client';
import { getCookie } from 'cookies-next';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React from 'react';

import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import {
  Button,
  Description,
  Illustration,
  IllustrationModal,
  ModalContent,
} from '@/shared/ui';
import { ButtonCode } from '@/shared/ui/button-code';
import { useStore } from '@/store/context';

export const PromocodeLanding = observer(() => {
  const auth = useStore().authorization;
  const router = useRouter();
  const token = getCookie('jwt');

  const handleButtonPress = () => {
    if (token) {
      auth.closeModal();
      router.push('/drop');
    } else {
      auth.setAuthRedirectPath('/drop');
      router.push('/');
      auth.openSignIn();
    }
  };

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'promo'} />
      <ModalContent.Title
        className={styles['promo-title']}
        title={token ? 'Get a free pet!' : 'Log in and get a pet!'}
        subtitle={'Free pet with a promo code!'}
      />

      <ModalContent.Body>
        <>
          <div className={styles['inputs-container']}>
            <Description
              message={
                token
                  ? 'A free pet is already waiting for you!'
                  : 'A free pet is already waiting for you! Just log in to your account, enter a promo code and collect your reward'
              }
            />
            <ButtonCode
              code={'TEST1'}
              icon={
                <Illustration
                  name={'ticket-sale'}
                  spriteName={'icons'}
                  style={{ color: '#6941C6' }}
                  spanTagClassName={styles['icon']}
                />
              }
            />
          </div>
        </>
      </ModalContent.Body>
      <ModalContent.Footer>
        <Button
          onClick={handleButtonPress}
          variant="primary"
          text={token ? 'SMASH EGG!' : 'Log in'}
          width="full-w"
          className={styles['bottom-container-button']}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
});
